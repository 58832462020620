/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker');
import { copyToClipboard } from 'utils/helpers';
import { titleCase, formatCurrency, currencySymbolizer, formatMoney } from '../utils/format';
import SiteSearch from 'utils/site_search';
import BillDataCollection from 'utils/bill_data_collection';
import * as Routes from 'routes';

declare global {
  interface Window {
    Advantage: any;
    Routes: any;
  }
}

declare const I18n;

window.Advantage = window.Advantage || {};
window.Routes = Routes;

window.Advantage.bindTooltips();
window.Advantage.Utils.SiteSearch = SiteSearch;
window.Advantage.Collections.BillDataCollection = BillDataCollection;

function assignGlobalFunctions(): void {
  window.Advantage.titleCase = titleCase;
  window.Advantage.copyToClipboard = copyToClipboard;
  window.Advantage.formatCurrency = formatCurrency;
  window.Advantage.currencySymbolizer = currencySymbolizer;
  window.Advantage.formatMoney = formatMoney;
}

assignGlobalFunctions();

declare var module: __WebpackModuleApi.Module;

if (module.hot) {
  module.hot.accept("../utils/format.ts", function() {
    console.log('Hot reloading ACG format functions');
    assignGlobalFunctions();
  });
}

Routes.configure({
  default_url_options: {
    locale: I18n.currentLocale()
  }
});
