import * as moment from "moment";


export function titleCase(str: string): string {
  let words = str.split('_');
  words = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return words.join(' ');
}

export function formatDateForKiwiList(dateStr: string): string {
  return dateStr ? moment(dateStr).format('MMM D YYYY') : '---';
}

import numeral from 'numeral';

interface FormatMoneyOptions {
  minAmount?: number;
  currencySymbol?: string;
}

export function formatMoney(amount: number, options: FormatMoneyOptions = {}): string {
  options = options || {};
  options.currencySymbol = options.currencySymbol || '$';
  amount = Math.round(amount);
  if (options.minAmount && amount < options.minAmount) {
    return options.currencySymbol + amount;
  } else {
    if (amount >= 1000000) {
      return `${options.currencySymbol}${(amount / 1000000)}M`;
    } else if (amount >= 1000) {
      return `${options.currencySymbol}${(amount / 1000)}k`;
    } else {
      return `${options.currencySymbol}${amount}`;
    }
  }
}


interface FormatCurrencyOptions {
  humanReadable?: boolean;
  absolute?: boolean;
  locale?: string;
  currency?: string
  currency_details?: {}
}

export function currencySymbolizer(options: FormatCurrencyOptions = {}): string {
  options.humanReadable = false;
  return formatCurrency(0.0, options).replace(/0\.?0*/, '');
}

export function formatCurrency (amount: number, options: FormatCurrencyOptions = {}): string {
  options = options || {};
  if (!amount && amount !== 0) {
    return '';
  }
  if (options.absolute) {
    amount = Math.abs(amount);
  }
  if (options.humanReadable) {
    let formatString = '';
    let powerOf10 = Math.floor(Math.log(amount) / Math.log(10));
    let currencySymbol = currencySymbolizer(options);

    powerOf10 = powerOf10 || 1; // 0 is weird and should be treated as 1 in this case( ie: $0.00)

    if (amount < 1000 || powerOf10 % 3 === 0) {
      formatString = '0.00a';
    } else if (powerOf10 % 3 === 1) {
      formatString = '0.0a';
    } else if (powerOf10 % 3 === 2) {
      formatString = '0a';
    }
    return currencySymbol + numeral(amount).format(formatString);
  } else {
    return customFormatCurrency(amount, options.currency_details);
  }
}

function customFormatCurrency (value:number, formatterProps: any): string {
  if (!value && value !== 0) {
    return '';
  }
  let regexDelimiter = /(\d)(?=(\d{3})$)/; // first pass regex to match to end of string
  if (formatterProps.displayPrecision > 0) {
    // if there is a decimal separator update the regex to match on the decial rather than end of string
    regexDelimiter = new RegExp(`(\\d)(?=(\\d{3})[${formatterProps.displaySeparator}])`);
  }
  let result = Math.abs(value).toFixed(formatterProps.displayPrecision);
  // set the decimal separator
  result = result.replace('.', formatterProps.displaySeparator);
  // the first pass of regex will set a delimiter for the thousands
  result = result.replace(regexDelimiter, `$1${formatterProps.displayDelimiter}`);
  // the next pass will set the delimiter char and delimiter spacing based on displayDelimiterExt and displayDelimitedExtSize
  // the default value for these is the displayDelimiter and 3, but some currencies will have unique values here
  regexDelimiter = new RegExp(`(\\d)(?=(\\d{${formatterProps.displayDelimiterExtSize}})+[${formatterProps.displayDelimiter}])`, 'g');
  result = result.replace(regexDelimiter, `$1${formatterProps.displayDelimiterExt}`);
  const numberMask = value < 0 ? formatterProps.displayFormatNegative : formatterProps.displayFormat;

  return numberMask.replace('%n', result).replace('%u', decodeHtml(formatterProps.htmlEntity));
}

function decodeHtml(html: string): string {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}