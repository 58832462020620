
import * as Routes from 'routes';

const BillDataCollection = window.Advantage.Collections.Base.extend({
  initialize: function (models, options) {
    this.billId = options.billId;
  },
  model: window.Advantage.Models.BillProductSummary,

  url: function () {
    return Routes.invoice_data_url(this.billId);
  }
});

export default BillDataCollection;
