/**
 * File generated by js-routes 2.2.5
 * Based on Rails 6.1.7.3 routes of CommandCenter::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (true &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * (/:locale)/alerts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alerts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"alerts"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/alerts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alerts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"alerts"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/api/git(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git_url = __jsr.r({"locale":{},"format":{"d":"json"}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/api/git(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git = __jsr.r({"locale":{},"format":{"d":"json"}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/api/git/change_id_exists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git_change_id_exists_url = __jsr.r({"locale":{},"format":{"d":"json"}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[2,[7,"/"],[2,[6,"change_id_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/api/git/change_id_exists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git_change_id_exists = __jsr.r({"locale":{},"format":{"d":"json"}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[2,[7,"/"],[2,[6,"change_id_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/api_tokens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_token_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api_tokens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/api_tokens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_token = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api_tokens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/api_tokens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tokens_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api_tokens"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/api_tokens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tokens = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"api_tokens"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /api/v1/cost_centers/:id/allocations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_allocations_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/cost_centers/:id/allocations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_allocations = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contract = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contracts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contracts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/cost_centers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_cost_center_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/cost_centers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_cost_center = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_cost_centers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_cost_centers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/deals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_deals_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"deals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/deals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_deals = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"deals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_employee_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_employee = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_employees_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_employees = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_location_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_location = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_locations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_locations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts/:id/notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_notes_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/contracts/:id/notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_notes = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_order_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_order = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts/:id/orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_orders_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/contracts/:id/orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_orders = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_provider_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_provider = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/:id/providers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_providers_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"providers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/locations/:id/providers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_providers = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"providers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_service_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_service = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/contracts/:id/services(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_services_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/contracts/:id/services(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_services = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_ticket_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_ticket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/:id/tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_tickets_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/locations/:id/tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_tickets = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/tickets/:id/updates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_updates_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/tickets/:id/updates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_updates = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/wwan_services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_wwan_services_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"wwan_services"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/wwan_services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_wwan_services = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"wwan_services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/approval_workflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_workflows_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"approval_workflows"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/approval_workflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_workflows = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"approval_workflows"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/assign_cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_cost_centers_billing_accounts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[6,"assign_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/assign_cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_cost_centers_billing_accounts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[6,"assign_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/assign_cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_cost_centers_services_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"assign_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/assign_cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_cost_centers_services = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"assign_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/assign_total_invoice_cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_total_invoice_cost_centers_billing_accounts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[6,"assign_total_invoice_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/assign_total_invoice_cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_total_invoice_cost_centers_billing_accounts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[6,"assign_total_invoice_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/bill_product_summaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bill_product_summary_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"bill_product_summaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/bill_product_summaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bill_product_summary = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"bill_product_summaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_account_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_account = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_accounts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_accounts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/contracts/contract_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_filters_contracts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"contract_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/contracts/contract_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_filters_contracts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"contract_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contracts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contracts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_allocations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_allocations_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_allocations"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_allocations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_allocations = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segment_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segment = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/confirm_inactive_modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_confirm_inactive_modal_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"confirm_inactive_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/confirm_inactive_modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_confirm_inactive_modal = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"confirm_inactive_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/edit_segment_form(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_edit_segment_form_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"edit_segment_form"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/edit_segment_form(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_edit_segment_form = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"edit_segment_form"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/replace_segment_modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_replace_segment_modal_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"replace_segment_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/replace_segment_modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_replace_segment_modal = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"replace_segment_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/set_active(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_set_active_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"set_active"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/set_active(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_set_active = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"set_active"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/set_inactive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_set_inactive_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"set_inactive"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_center_segments/set_inactive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_center_segments_set_inactive = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_center_segments"],[2,[7,"/"],[2,[6,"set_inactive"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_centers_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_centers = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/contacts/create_user_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_user_account_contacts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"create_user_account"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/contacts/create_user_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_user_account_contacts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"create_user_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/portal/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/portal/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/expense_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_expense_management_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"expense_management"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/expense_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_expense_management = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"expense_management"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/invoices_validated(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_invoices_validated_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"invoices_validated"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/invoices_validated(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_invoices_validated = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"invoices_validated"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/reset_to_default(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_reset_to_default_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"reset_to_default"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/reset_to_default(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_reset_to_default = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"reset_to_default"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/service_type_count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_service_type_count_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"service_type_count"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/service_type_count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_service_type_count = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"service_type_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/spend_by_provider(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_spend_by_provider_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"spend_by_provider"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/spend_by_provider(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_spend_by_provider = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"spend_by_provider"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/spend_by_service_type(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_spend_by_service_type_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"spend_by_service_type"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/spend_by_service_type(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_spend_by_service_type = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"spend_by_service_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/top_five_spend_by_cost_center(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_top_five_spend_by_cost_center_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"top_five_spend_by_cost_center"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/top_five_spend_by_cost_center(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_top_five_spend_by_cost_center = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"top_five_spend_by_cost_center"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/top_five_spend_by_provider(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_top_five_spend_by_provider_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"top_five_spend_by_provider"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/top_five_spend_by_provider(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_top_five_spend_by_provider = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"top_five_spend_by_provider"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/update_dashlet_positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_update_dashlet_positions_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"update_dashlet_positions"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/update_dashlet_positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_update_dashlet_positions = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"update_dashlet_positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/update_dashlets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_update_dashlets_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"update_dashlets"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/update_dashlets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_update_dashlets = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"update_dashlets"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/dashboards/update_layout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_update_layout_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"update_layout"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/dashboards/update_layout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_update_layout = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"update_layout"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/directories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const directories_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"directories"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/directories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const directories = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"directories"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/documents/get_history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_get_history_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"get_history"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/documents/get_history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_get_history = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"get_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/documents/pin_to_top(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_pin_to_top_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"pin_to_top"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/documents/pin_to_top(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_pin_to_top = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"pin_to_top"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/documents/upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_upload_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/documents/upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_upload = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_document_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_document = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_cost_center_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_cost_center = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/passwords/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/passwords/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/images/background_image(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const images_background_image_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[6,"background_image"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/images/background_image(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const images_background_image = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[6,"background_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/images/login_logo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const images_login_logo_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[6,"login_logo"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/images/login_logo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const images_login_logo = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[6,"login_logo"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/impersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/impersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/portal/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const initialize_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/portal/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const initialize = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/portal/inventory_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_dashlet_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"inventory_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/portal/inventory_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inventory_dashlet = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"inventory_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/invoices/:invoice_id/data(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_data_url = __jsr.r({"locale":{},"invoice_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/invoices/:invoice_id/data(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_data = __jsr.r({"locale":{},"invoice_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/kiwi/test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kiwi_test_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"kiwi"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/kiwi/test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kiwi_test = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"kiwi"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locations_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locations = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/login/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_verify_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"login"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/login/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_verify = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"login"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/map(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"map"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/map(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"map"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id/mark_as_active(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_as_active_cost_center_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_active"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id/mark_as_active(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_as_active_cost_center = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_active"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id/mark_as_inactive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_as_inactive_cost_center_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_inactive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/:id/mark_as_inactive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_as_inactive_cost_center = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_inactive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cost_center_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/cost_centers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cost_center = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/passwords/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_password_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/passwords/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_password = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/session/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/session/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notes_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notes = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/orders/:order_id/activities(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_activities_url = __jsr.r({"locale":{},"order_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/orders/:order_id/activities(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_activities = __jsr.r({"locale":{},"order_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/orders/order_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_filters_orders_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"order_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/orders/order_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_filters_orders = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"order_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/passwords/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/passwords/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/providers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"providers"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/providers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"providers"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_url = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_url = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /(:locale)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_url = __jsr.r({"locale":{},"format":{}}, [2,[7,"/"],[2,[1,[3,"locale"]],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /(:locale)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root = __jsr.r({"locale":{},"format":{}}, [2,[7,"/"],[2,[1,[3,"locale"]],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * (/:locale)/search/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/search/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/images/:id/serve_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serve_image_image_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"serve_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/images/:id/serve_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serve_image_image = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"serve_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/:service_id/activities(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activities_url = __jsr.r({"locale":{},"service_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/:service_id/activities(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activities = __jsr.r({"locale":{},"service_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/service_attributes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_attributes_services_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"service_attributes"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/service_attributes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_attributes_services = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"service_attributes"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/service_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_filters_services_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"service_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/service_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_filters_services = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"service_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/:service_id/milestones(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_milestones_url = __jsr.r({"locale":{},"service_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"milestones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/:service_id/milestones(.:format)
 * @param {any} service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_milestones = __jsr.r({"locale":{},"service_id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"service_id"],[2,[7,"/"],[2,[6,"milestones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const services_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const services = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"session"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"session"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/sessions/keep_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_keep_alive_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"keep_alive"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/sessions/keep_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_keep_alive = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"keep_alive"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/sessions/log_browser_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_log_browser_stats_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"log_browser_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/sessions/log_browser_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_log_browser_stats = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"log_browser_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/sessions/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_logout_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/sessions/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_logout = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/sessions/time_remaining(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_time_remaining_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"time_remaining"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/sessions/time_remaining(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_time_remaining = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"time_remaining"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/accept_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_accept_terms_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"accept_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/accept_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_accept_terms = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"accept_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/api_credentials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_api_credentials_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"api_credentials"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/api_credentials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_api_credentials = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"api_credentials"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/landing_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_landing_page_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/landing_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_landing_page = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/landing_page/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_landing_page_update_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"landing_page"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/landing_page/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_landing_page_update = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"landing_page"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_notes_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_notes = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_notifications_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_notifications = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/notifications/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_notifications_update_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/notifications/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_notifications_update = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_password_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_password = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/password/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_password_update_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/password/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_password_update = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/states_by_region(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_states_by_region_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"states_by_region"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/states_by_region(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_states_by_region = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"states_by_region"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/terms_of_use(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_terms_of_use_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"terms_of_use"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/terms_of_use(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_terms_of_use = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"terms_of_use"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/your_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_your_information_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"your_information"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/your_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_your_information = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"your_information"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/settings/your_information/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_your_information_update_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"your_information"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/settings/your_information/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_your_information_update = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"your_information"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/services/spend_and_inventory(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const spend_and_inventory_services_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"spend_and_inventory"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/services/spend_and_inventory(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const spend_and_inventory_services = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"spend_and_inventory"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/portal/spend_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const spend_dashlet_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"spend_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/portal/spend_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const spend_dashlet = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"spend_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/sub_accounts_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sub_accounts_dashlet_billing_accounts_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[6,"sub_accounts_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/billing_accounts/sub_accounts_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sub_accounts_dashlet_billing_accounts = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"billing_accounts"],[2,[7,"/"],[2,[6,"sub_accounts_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/tickets/ticket_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket_filters_tickets_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"ticket_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/tickets/ticket_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket_filters_tickets = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"ticket_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/portal/tickets_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets_dashlet_url = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"tickets_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/portal/tickets_dashlet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets_dashlet = __jsr.r({"locale":{},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"tickets_dashlet"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/invoices/:id/update_approval_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_approval_status_invoice_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_approval_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/invoices/:id/update_approval_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_approval_status_invoice = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_approval_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_url = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * (/:locale)/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_url = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * (/:locale)/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user = __jsr.r({"locale":{},"id":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/hubspot/deals/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_hubspot_deals_sync_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"hubspot"],[2,[7,"/"],[2,[6,"deals"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/hubspot/deals/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_hubspot_deals_sync = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"hubspot"],[2,[7,"/"],[2,[6,"deals"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/twilio/receive_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_twilio_receive_message_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"twilio"],[2,[7,"/"],[2,[6,"receive_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/twilio/receive_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_twilio_receive_message = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"twilio"],[2,[7,"/"],[2,[6,"receive_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

