
import * as Routes from 'routes';

/**
 * Global site search
 */
const SiteSearch = window.Advantage.Models.Base.extend({
  url: Routes.search_url(),

  currentRequest: null,

  search: function (searchTerm) {
    if (this.currentRequest) {
      this.currentRequest.abort();
    }
    if (searchTerm !== '') {
      this.trigger('beginSearch');
      this.currentRequest = this.fetch({
        data: {
          search_term: searchTerm
        },
        success: function () {
          this.trigger('onSearchSuccess');
        }.bind(this)
      });
    } else {
      this.clear();
      this.trigger('onSearchSuccess');
    }
  },

  empty: function () {
    return !(
      (this.get('contracts') && this.get('contracts').length > 0) ||
      (this.get('locations') && this.get('locations').length > 0) ||
      (this.get('orders') && this.get('orders').length > 0) ||
      (this.get('services') && this.get('services').length > 0) ||
      (this.get('tickets') && this.get('tickets').length > 0) ||
      (this.get('providers') && this.get('providers').length > 0) ||
      (this.get('billingAccounts') && this.get('billingAccounts').length > 0) ||
      (this.get('invoices') && this.get('invoices').length > 0)
    );
  }
});

export default SiteSearch;
